import {
  Divider,
  styled,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Box,
  InputAdornment,
  List,
  IconButton,
  FormGroup,
} from '@mui/material';
import { themeOptions } from 'assets/styles/theme';
import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import { Field, Item } from 'store/states/forms/types';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';

interface RadioBoxProps {
  setValue: (value: string) => void;
  defaultValue: string;
  onCustomItemAdded: (items: Item) => void;
  onCustomItemRemoved: (items: Item) => void;
  field: Field;
}

const StyledFomControlLabel = styled(FormControlLabel)`
  color: ${themeOptions.palette?.text?.primary};
`;

export default function RadioBox({
  setValue,
  defaultValue,
  onCustomItemAdded,
  onCustomItemRemoved,
  field,
}: RadioBoxProps) {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    const value =
      typeof event === 'string'
        ? event
        : (event.target as HTMLInputElement).value;
    if (value === defaultValue) {
      setValue('');
    } else {
      setValue(value);
    }
  };
  const [newItemValue, setNewItemValue] = useState<string>('');
  const [searchText, setSearchText] = useState('');

  const items = useMemo(() => field.items ?? [], [field.items]);

  const canMoreOptionsBeAdded = () => {
    const allowIncludeMoreOptions = field.rules?.allowIncludeMoreOptions;
    const limitIncludeMoreOptions = field.rules?.limitIncludeMoreOptions;

    if (!allowIncludeMoreOptions) return false;

    if (limitIncludeMoreOptions) {
      const customItems = items.filter((item) => item.isCustom);
      return customItems.length < limitIncludeMoreOptions;
    }

    return false;
  };

  const filteredItems = useMemo(() => {
    const result = items.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase()),
    );

    const defaultItems: Item[] = [];
    const customItems: Item[] = [];

    result.forEach((item) => {
      if (item.isCustom) {
        customItems.push(item);
      } else {
        defaultItems.push(item);
      }
    });

    defaultItems.sort((a, b) =>
      a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()),
    );

    customItems.sort((a, b) =>
      a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()),
    );

    return [...defaultItems, ...customItems];
  }, [searchText, items]);

  const arrayLastItem = filteredItems.length - 1;

  return (
    <RadioGroup
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
      onChange={handleChange}
      value={defaultValue}
      name="radio-buttons-group"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        {items.length > 5 && (
          <TextField
            sx={{ width: '100%' }}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="inherit" />
                </InputAdornment>
              ),
            }}
          />
        )}

        <List
          style={{
            overflowY: 'auto',
            maxHeight: '100%',
          }}
        >
          {filteredItems.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  marginBlock: 8,
                  marginRight: 16,
                  width: '100%',
                }}
              >
                {item.isCustom ? (
                  <div
                    style={{
                      marginBlock: 16,
                      marginRight: 16,
                    }}
                  >
                    <Box style={{ width: '100%', flexGrow: 1 }}>
                      <FormGroup>
                        <StyledFomControlLabel
                          sx={{
                            input: {
                              cursor: 'pointer',
                            },
                          }}
                          value={item?.value}
                          control={
                            <Radio
                              disabled={React.isValidElement(
                                filteredItems[index]?.label,
                              )}
                            />
                          }
                          label={
                            <TextField
                              fullWidth
                              sx={{
                                width: '145%',
                              }}
                              value={item.label}
                              onChange={(event) =>
                                setNewItemValue(event.target.value)
                              }
                              onClick={() => handleChange(item.value)}
                              disabled={false}
                              label={t('others')}
                              placeholder={t('digitHere')}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <IconButton
                                    onClick={() => onCustomItemRemoved(item)}
                                  >
                                    <CancelIcon
                                      style={{
                                        color: themeOptions.palette.grey[400],
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </IconButton>
                                ),
                              }}
                              onKeyUp={(event) => {
                                const value = newItemValue?.trim();
                                if (event.key === 'Enter' && value) {
                                  onCustomItemAdded({
                                    label: value,
                                    value,
                                    id: value,
                                    isCustom: true,
                                    name: value,
                                  });
                                  handleChange(value);
                                  setNewItemValue('');
                                }
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </Box>
                  </div>
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      flexGrow: 1,
                      marginTop: React.isValidElement(
                        filteredItems[index]?.label,
                      )
                        ? '10px'
                        : 0,
                    }}
                  >
                    <StyledFomControlLabel
                      value={item?.value}
                      control={
                        <Radio disabled={React.isValidElement(item?.label)} />
                      }
                      label={item?.label || item?.value || ''}
                    />
                  </Box>
                )}
              </div>

              {(canMoreOptionsBeAdded() || index !== arrayLastItem) && (
                <Divider />
              )}
            </div>
          ))}

          {canMoreOptionsBeAdded() && (
            <div style={{ marginBlock: 24, marginRight: 16 }}>
              <StyledFomControlLabel
                value={newItemValue}
                control={<Radio value={false} />}
                label={
                  <TextField
                    value={newItemValue}
                    sx={{
                      width: '175%',
                    }}
                    onChange={(event) => setNewItemValue(event.target.value)}
                    disabled={false}
                    label={t('others')}
                    placeholder={t('digitHere')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyUp={(event) => {
                      const value = newItemValue?.trim();
                      if (event.key === 'Enter' && value) {
                        onCustomItemAdded({
                          label: value,
                          value,
                          id: value,
                          isCustom: true,
                          name: value,
                        });
                        handleChange(value);
                        setNewItemValue('');
                      }
                    }}
                  />
                }
              />
            </div>
          )}
        </List>
      </div>
    </RadioGroup>
  );
}
