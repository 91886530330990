import React, { useEffect, useMemo, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

import {
  FormLabel,
  Checkbox,
  Divider,
  FormControl,
  ListItem,
  TextField,
  styled,
  List,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Field, Item } from 'store/states/forms/types';
import { themeOptions } from 'assets/styles/theme';
import { addCustomInitialItemsOptions } from 'store/states/forms/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface FieldCheckboxProps {
  setMultipleValues: (value: string[]) => void;
  defaultValues: string[];
  onCustomItemAdded: (items: Item) => void;
  onCustomItemRemoved: (items: Item) => void;
  field: Field;
}

const StyledFormLabel = styled(FormLabel)`
  color: ${themeOptions.palette?.text?.primary};
`;

export default function FieldCheckbox({
  field,
  setMultipleValues,
  defaultValues,
  onCustomItemAdded,
  onCustomItemRemoved,
}: FieldCheckboxProps) {
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    const value =
      typeof event === 'string'
        ? event
        : (event.target as HTMLInputElement).value;
    if (defaultValues.includes(value)) {
      setMultipleValues(defaultValues.filter((item) => item !== value));
    } else {
      setMultipleValues([...defaultValues, value]);
    }
  };

  const items = field.items || [];

  useEffect(() => {
    const fieldsToAdd: string[] = [];

    defaultValues.forEach((value) => {
      const answerFinded = items.find((item) => item.value === value);
      if (!answerFinded) {
        fieldsToAdd.push(value);
      }
    });

    if (fieldsToAdd.length) {
      dispatch(
        addCustomInitialItemsOptions(
          field.field_id,
          fieldsToAdd.map((item) => ({
            id: item,
            name: item,
            label: item,
            value: item,
            isCustom: true,
          })),
        ),
      );
    }
  }, []);

  const [newItemValue, setNewItemValue] = useState<string>('');

  const arrayLastItem = items && items.length - 1;

  const canMoreOptionsBeAdded = () => {
    const allowIncludeMoreOptions = field.rules?.allowIncludeMoreOptions;
    const limitIncludeMoreOptions = field.rules?.limitIncludeMoreOptions;

    if (!allowIncludeMoreOptions) return false;

    if (limitIncludeMoreOptions) {
      const customItems = items.filter((item) => item.isCustom);
      return customItems.length < limitIncludeMoreOptions;
    }

    return false;
  };

  const filteredItems = useMemo(() => {
    const result = items.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase()),
    );

    const defaultItems: Item[] = [];
    const customItems: Item[] = [];

    result.forEach((item) => {
      if (item.isCustom) {
        customItems.push(item);
      } else {
        defaultItems.push(item);
      }
    });

    defaultItems.sort((a, b) =>
      a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()),
    );

    customItems.sort((a, b) =>
      a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()),
    );

    return [...defaultItems, ...customItems];
  }, [searchText, items]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {items.length > 5 && (
        <TextField
          sx={{ width: '100%' }}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="inherit" />
              </InputAdornment>
            ),
          }}
        />
      )}

      <List style={{ overflowY: 'auto', maxHeight: '100%' }}>
        {filteredItems.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ marginBlock: 8, marginRight: 16 }}>
              {item.isCustom ? (
                <ListItem disablePadding style={{}}>
                  <Checkbox
                    value={item.value}
                    checked={defaultValues.includes(item.value)}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    sx={{
                      input: {
                        cursor: 'pointer',
                      },
                    }}
                    value={item.value}
                    onChange={(event) => setNewItemValue(event.target.value)}
                    disabled={false}
                    label={t('other')}
                    onClick={() => handleChange(item.value)}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton onClick={() => onCustomItemRemoved(item)}>
                          <CancelIcon
                            style={{
                              color: themeOptions.palette.grey[400],
                              cursor: 'pointer',
                            }}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </ListItem>
              ) : (
                <ListItem disablePadding>
                  <Checkbox
                    checked={defaultValues.includes(item.value)}
                    onChange={handleChange}
                    value={item.value}
                  />
                  <StyledFormLabel focused={false}>
                    {item.label}
                  </StyledFormLabel>
                </ListItem>
              )}
            </div>
            {(canMoreOptionsBeAdded() || index !== arrayLastItem) && (
              <Divider />
            )}
          </div>
        ))}
        {canMoreOptionsBeAdded() && (
          <div style={{ marginBlock: 16, marginRight: 16 }}>
            <ListItem disablePadding>
              <Checkbox checked={false} onChange={handleChange} />
              <TextField
                fullWidth
                value={newItemValue}
                onChange={(event) => setNewItemValue(event.target.value)}
                disabled={false}
                label={t('others')}
                placeholder={t('digitHere')}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyUp={(event) => {
                  const value = newItemValue?.trim();
                  if (event.key === 'Enter' && value) {
                    onCustomItemAdded({
                      label: value,
                      value,
                      id: value,
                      isCustom: true,
                      name: value,
                    });
                    handleChange(value);
                    setNewItemValue('');
                  }
                }}
              />
            </ListItem>
          </div>
        )}
      </List>
    </div>
  );
}
