/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { AnswerEdit, Form, FormsActionTypes, FormState } from './types';
import { HistoricAnswer } from '../historics/types';
import {
  clearAnswersToNull,
  formatForm,
  gerenateAutoFieldValue,
} from './utils';

const INITIAL_STATE: FormState = {
  selectedForm: {} as unknown as Form,
  fillData: {
    step: 0,
    totalSteps: 0,
    notHasLastQuestion: false,
    answers: [],
    fieldStack: [],
    answerSuccess: false,
    answerError: false,
    answerLoading: false,
  },
  autoGeneratedValue: '',
  answerEdit: {} as unknown as AnswerEdit,
};

const forms: Reducer<FormState> = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FormsActionTypes.SET_SELECTED_FORM: {
        const { form, organization, userData, externalId } = action.payload;

        draft.autoGeneratedValue = gerenateAutoFieldValue({
          externalId,
          firstName: userData.name ?? '',
          lastName: userData.last_name ?? '',
        });

        draft.selectedForm = formatForm(form, organization, externalId);
        draft.fillData.step = 0;
        draft.fillData.totalSteps = form.fields.length;
        draft.fillData.answers = draft.selectedForm.fields.map((field) => ({
          field_id: field.field_id,
          value: null,
        }));
        draft.fillData.fieldStack = [0];
        draft.fillData.answerSuccess = false;
        draft.fillData.answerError = false;
        draft.fillData.notHasLastQuestion = false;

        if (draft.selectedForm.fields[0].type === 'auto_generated') {
          draft.fillData.answers[0].value = draft.autoGeneratedValue;
        }

        break;
      }

      case FormsActionTypes.NEXT_STEP_SUCCESS: {
        const { answer, nextFieldIndex, answerIndex, notHasLastQuestion } =
          action.payload;
        if (answerIndex !== undefined && answerIndex >= 0) {
          if (
            draft.selectedForm.fields[answerIndex].type === 'auto_generated'
          ) {
            draft.fillData.answers[answerIndex].value =
              draft.autoGeneratedValue;
          } else {
            draft.fillData.answers[answerIndex].value = answer.value;
          }
        }

        draft.fillData.fieldStack.push(nextFieldIndex);
        draft.fillData.step = nextFieldIndex;
        draft.fillData.notHasLastQuestion = notHasLastQuestion;
        break;
      }
      case FormsActionTypes.PREVIOUS_STEP: {
        const { step, fieldStack } = draft.fillData;

        if (step > 0) {
          draft.fillData.fieldStack.pop();
          draft.fillData.step = fieldStack[fieldStack.length - 1];
        }
        break;
      }
      case FormsActionTypes.SEND_FORM_REQUEST: {
        draft.fillData.answerLoading = true;
        draft.fillData.answerSuccess = false;
        draft.fillData.answerError = false;
        break;
      }
      case FormsActionTypes.SEND_FORM_SUCCESS: {
        draft.fillData.answerSuccess = true;
        draft.fillData.answerLoading = false;
        break;
      }
      case FormsActionTypes.SEND_FORM_ERROR: {
        draft.fillData.answerError = true;
        draft.fillData.answerLoading = false;
        break;
      }
      case FormsActionTypes.SET_ANSWERS: {
        const { answers } = action.payload;

        const newAnswers = answers.map((answer: HistoricAnswer) => ({
          field_id: answer.field_id,
          value: answer.answer,
        }));

        draft.fillData.answers = newAnswers;
        break;
      }
      case FormsActionTypes.SET_EDIT_PARAMS: {
        const { step, fieldStack } = action.payload;
        draft.fillData.step = step;
        draft.fillData.fieldStack = fieldStack;
        break;
      }
      case FormsActionTypes.UPDATE_ANSWER: {
        const updatedAnswer = action.payload;
        const { answers } = draft.fillData;
        const answerIndex = answers.findIndex(
          (findAnswer) => findAnswer.field_id === updatedAnswer.field_id,
        );
        const answerExists = answerIndex !== -1;
        const indexToNewAnswer = draft.fillData.answers.length;

        draft.fillData.answers[answerExists ? answerIndex : indexToNewAnswer] =
          updatedAnswer;

        break;
      }
      case FormsActionTypes.CLEAR_EDITED_ANSWERS: {
        draft.answerEdit = {} as unknown as AnswerEdit;
        break;
      }
      case FormsActionTypes.CLEAR_INVALID_ANSWERS: {
        const { startIndex } = action.payload;
        const { answers } = draft.fillData;
        draft.fillData.answers = clearAnswersToNull(answers, startIndex);
        break;
      }

      case FormsActionTypes.ADD_CUSTOM_ITEM_OPTION: {
        const { fieldId, item } = action.payload;
        const index = draft.selectedForm.fields.findIndex(
          (f) => f.field_id === fieldId,
        );
        if (index !== -1) {
          draft.selectedForm.fields[index].items.push(item);
        }
        break;
      }

      case FormsActionTypes.REMOVE_CUSTOM_ITEM_OPTION: {
        const { fieldId, item } = action.payload;
        const index = draft.selectedForm.fields.findIndex(
          (f) => f.field_id === fieldId,
        );
        if (index !== -1) {
          draft.selectedForm.fields[index].items = draft.selectedForm.fields[
            index
          ].items.filter((i) => i.id !== item.id);
        }
        break;
      }

      case FormsActionTypes.ADD_CUSTOM_INITIAL_ITEMS_OPTIONS: {
        const { fieldId, items } = action.payload;
        const index = draft.selectedForm.fields.findIndex(
          (f) => f.field_id === fieldId,
        );
        if (index !== -1) {
          draft.selectedForm.fields[index].items.push(...items);
        }
        break;
      }

      default:
        return state;
    }
  });

export default forms;
